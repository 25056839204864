import { writable } from 'svelte/store';
import REGISTER_CONFIG from 'configs/register';
import SHARED_CONFIG from 'configs/shared';

const initialValue = {
    [REGISTER_CONFIG.STEPS.ESSENTIALS.key]: {
        loanType: null,
        country: null,
        platform: SHARED_CONFIG.PLATFORMS.AXCEL_FINANCE,
    },
    [REGISTER_CONFIG.STEPS.DETAILS.key]: {
        firstName: null,
        lastName: null,
        emailAddress: null,
        confirmEmailAddress: null,
        dob: {
            date: null,
            day: null,
            month: null,
            year: null,
        },
        gender: null,
        pep: {
            label: 'No',
            value: false,
        },
        phoneNumber: {
            primary: {
                full: '',
                start: null,
                middle: null,
                end: null,
            },
        },
        address: {
            street: null,
            city: null,
            country: null,
        },
    },
    [REGISTER_CONFIG.STEPS.FINANCIALS.key]: {
        bankName: null,
        bankAccount: {
            number: null,
            type: null,
        },
        bankAddress: {
            street: null,
            city: null,
            country: null,
        },
    },
    [REGISTER_CONFIG.STEPS.EMPLOYER.key]: {
        name: null,
        income: {
            amount: 10000,
            frequency: {
                type: { label: 'Monthly', value: 'Monthly' },
                dayOfWeek: { label: 'Friday', value: 'Friday' },
                dayOfMonth: {
                    label: 'Last Day',
                    value: 'LastDay',
                },
                date: new Date().toLocaleDateString('en-US', {}),
            },
        },
        occupation: null,
        hireDate: null,
        address: {
            street: null,
            city: null,
            country: null,
        },
        disclosures: {
            credit: false,
            loanStatus: false,
        },
    },
    [REGISTER_CONFIG.STEPS.BUSINESS.key]: {
        name: null,
        address: {
            street: null,
            city: null,
            country: null,
        },
        phoneNumber: {
            full: '',
            start: null,
            middle: null,
            end: null,
        },
        startDate: {
            date: null,
            day: null,
            month: null,
            year: null,
        },
        numEmployees: {
            fullTime: 0,
            partTime: 0,
        },
        averageMonthlySalesRevenue: null,
        outstandingLoans: null,
        outstandingLoansDescription: null,
        reasonForBorrowing: null,
        disclosures: {
            transunion: null,
        },
    },
    [REGISTER_CONFIG.STEPS.CAR.key]: {
        driversLicense: null,
        vehicleDebt: {
            hasDebt: null,
            balance: null,
            monthlyPayment: null,
        },
        insurance: {
            isValid: null,
            type: null,
            company: null,
        },
        licensePlateNumber: null,
        vehicle: {
            make: null,
            model: null,
            year: null,
        },
        disclosures: {
            transunion: null,
        },
    },
    [REGISTER_CONFIG.STEPS.LOAN.key]: {
        amount: null,
        salary: null,
        car: {
            loanType: null,
            amount: null,
            downPayment: null,
        },
        isExistingCustomer: {
            label: 'Yes',
            value: true,
        },
        numPayments: null,
        monthlyPaymentAmount: null,
        monthlyPaymentAmountBasicInsurance: null,
        monthlyPaymentAmountProInsurance: null,
        income: { amount: null },
        borrowAmountMultiplier: null,
        reason: null,
    },
    [REGISTER_CONFIG.STEPS.INSURANCE.key]: {
        insurance: SHARED_CONFIG.PERSONAL_INSURANCE_TYPES.PRO.id,
    },
    [REGISTER_CONFIG.STEPS.CREDENTIALS.key]: {
        username: null,
        password: null,
        repeatPassword: null,
    },

    isComplete: false,
};

function createStore() {
    const { subscribe, set } = writable(initialValue);

    return {
        subscribe,
        set,

        populate: () => {
            set({
                essentials: {
                    loanType: null,
                    country: null,
                    platform: SHARED_CONFIG.PLATFORMS.AXCEL_FINANCE,
                },
                details: {
                    firstName: 'John',
                    lastName: 'Smith',
                    emailAddress: 'johnsmith@test.com',
                    confirmEmailAddress: 'johnsmith@test.com',
                    dob: {
                        date: '1988-12-12',
                        day: null,
                        month: null,
                        year: null,
                    },
                    gender: {
                        label: 'Male',
                        value: 'Male',
                    },
                    pep: {
                        label: 'No',
                        value: false,
                    },
                    phoneNumber: {
                        primary: {
                            full: '246-123-1234',
                            start: null,
                            middle: null,
                            end: null,
                        },
                    },
                    address: {
                        street: 'Test Street',
                        city: 'Test City',
                        country: {
                            label: 'Trindad & Tobago',
                            value: 'TT',
                            isSME: true,
                            isCar: true,
                        },
                    },
                },
                insurance: {
                    insurance: null,
                },
                financials: {
                    bankName: 'BMO',
                    bankAccount: {
                        number: '123456789',
                        type: {
                            label: 'Checking',
                            value: 'CheckingAccount',
                        },
                    },
                    bankAddress: {
                        street: null,
                        city: null,
                        country: null,
                    },
                },
                employer: {
                    name: 'Test Employer',
                    income: {
                        amount: 25000,
                        frequency: {
                            type: {
                                label: 'Weekly',
                                value: 'Weekly',
                            },
                            dayOfWeek: {
                                label: 'Tuesday',
                                value: 'Tuesday',
                            },
                            dayOfMonth: {
                                label: 'Last Day',
                                value: 'LastDay',
                            },
                            date: '2024-12-12',
                        },
                    },
                    occupation: 'Test Occupation',
                    hireDate: '2010-08-05',
                    address: {
                        street: 'Test Street',
                        city: 'Test City',
                        country: {
                            label: 'Trindad & Tobago',
                            value: 'TT',
                            isSME: true,
                            isCar: true,
                        },
                    },
                    disclosures: {
                        credit: {
                            label: 'Yes',
                            value: true,
                        },
                        loanStatus: {
                            label: 'Yes',
                            value: true,
                        },
                    },
                },
                business: {
                    name: null,
                    address: {
                        street: null,
                        city: null,
                        country: null,
                    },
                    phoneNumber: {
                        full: '',
                        start: null,
                        middle: null,
                        end: null,
                    },
                    startDate: {
                        date: null,
                        day: null,
                        month: null,
                        year: null,
                    },
                    numEmployees: {
                        fullTime: 0,
                        partTime: 0,
                    },
                    averageMonthlySalesRevenue: null,
                    outstandingLoans: null,
                    outstandingLoansDescription: null,
                    reasonForBorrowing: null,
                    disclosures: {
                        transunion: null,
                    },
                },
                car: {
                    driversLicense: null,
                    vehicleDebt: {
                        hasDebt: null,
                        balance: null,
                        monthlyPayment: null,
                    },
                    insurance: {
                        isValid: null,
                        type: null,
                        company: null,
                    },
                    licensePlateNumber: null,
                    vehicle: {
                        make: null,
                        model: null,
                        year: null,
                    },
                    disclosures: {
                        transunion: null,
                    },
                },
                loan: {
                    amount: 25000,
                    salary: 25000,
                    car: {
                        downPayment: null,
                    },
                    numPayments: {
                        label: '6 Months',
                        value: 6,
                    },
                    income: {
                        amount: null,
                    },
                    borrowAmountMultiplier: {
                        label: '1 year or less',
                        value: 1,
                    },
                    reason: {
                        label: 'Personal',
                        value: 'Personal',
                    },
                },
                credentials: {
                    username: null,
                    password: null,
                    repeatPassword: null,
                },

                isComplete: false,
            });
        },

        reset: () => {
            set(initialValue);
        },
    };
}

export default createStore();
