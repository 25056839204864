<script>
    import { onMount } from 'svelte';
    import { createEventDispatcher } from 'svelte';
    import Select from 'svelte-select';
    import RangeSlider from 'svelte-range-slider-pips';

    import SHARED_CONFIG from 'configs/shared';
    import REGISTER_CONFIG from 'configs/register';
    import CALCULATOR_CONFIG from 'configs/calculator';

    import { formatCurrency } from 'services/util';
    import { calculate } from 'services/calculator';

    import registerStore from 'stores/register';

    const dispatch = createEventDispatcher();

    export let country = SHARED_CONFIG.COUNTRIES.TT;
    export let loanType = SHARED_CONFIG.LOAN_TYPES.PERSONAL;
    export let activeLoan = null;
    export let disabled = false;

    let calculatorType;

    // Shared params
    let borrowAmountMin;
    let borrowAmountMax;
    let borrowAmountStep;
    let borrowAmount;
    let borrowAmountMultiplier;
    let loanReason;

    // Car Loan params
    let carLoanType;
    let carAmount;

    let carDownPaymentAmount;
    let carDownPaymentAmountMin;
    let carDownPaymentAmountMax;

    // Personal Loan params
    let salaryAmount;

    let numPayments;
    let numPaymentsOptions = [];
    let loanReasonOptions = REGISTER_CONFIG.OPTIONS.LOAN_REASONS;
    let totalInterestCharge;
    let monthlyPayments = [];

    let carLoanInterestCharge;
    let carLoanLegalFees;

    $: {
        calculatorType = CALCULATOR_CONFIG.TYPES[loanType];
        activeLoan = activeLoan;
        loadNumPaymentsOptions();
    }

    onMount(() => {
        loadNumPaymentsOptions();

        switch (loanType) {
            case SHARED_CONFIG.LOAN_TYPES.CAR:
                carLoanType =
                    $registerStore[REGISTER_CONFIG.STEPS.LOAN.key].car.loanType ??
                    calculatorType.inputs.loanType.options[0];
                carAmount =
                    $registerStore[REGISTER_CONFIG.STEPS.LOAN.key].car.amount ??
                    calculatorType.inputs.car[carLoanType.value].default;
                carDownPaymentAmount =
                    $registerStore[REGISTER_CONFIG.STEPS.LOAN.key].car.downPayment ??
                    calculatorType.inputs.downPayment.default;
                carDownPaymentAmountMin = calculatorType.inputs.downPayment.minAmount;
                carDownPaymentAmountMax = calculatorType.inputs.downPayment.maxAmount;
                borrowAmountMin = calculatorType.inputs.borrowing.limits[country.key].minAmount;
                borrowAmountMax = calculatorType.inputs.borrowing.limits[country.key].maxAmount;
                borrowAmountStep = calculatorType.inputs.borrowing.limits[country.key].step;
                borrowAmount = $registerStore[REGISTER_CONFIG.STEPS.LOAN.key].amount
                    ? $registerStore[REGISTER_CONFIG.STEPS.LOAN.key].amount
                    : calculatorType.inputs.borrowing.limits[country.key].defaultBorrowAmount;
                numPayments = $registerStore[REGISTER_CONFIG.STEPS.LOAN.key].numPayments
                    ? $registerStore[REGISTER_CONFIG.STEPS.LOAN.key].numPayments
                    : numPaymentsOptions[1];
                loanReason = $registerStore[REGISTER_CONFIG.STEPS.LOAN.key].reason ?? {
                    label: 'Vehicle',
                    value: 'Vehicle',
                };
                break;
            case SHARED_CONFIG.LOAN_TYPES.BUSINESS:
                borrowAmountMin = calculatorType.inputs.borrowing.limits[country.key].minAmount;
                borrowAmountMax = calculatorType.inputs.borrowing.limits[country.key].maxAmount;
                borrowAmountStep = calculatorType.inputs.borrowing.limits[country.key].step;
                borrowAmount = $registerStore[REGISTER_CONFIG.STEPS.LOAN.key].amount
                    ? $registerStore[REGISTER_CONFIG.STEPS.LOAN.key].amount
                    : calculatorType.inputs.borrowing.limits[country.key].defaultBorrowAmount;
                numPayments = $registerStore[REGISTER_CONFIG.STEPS.LOAN.key].numPayments
                    ? $registerStore[REGISTER_CONFIG.STEPS.LOAN.key].numPayments
                    : numPaymentsOptions[4];
                loanReason = $registerStore[REGISTER_CONFIG.STEPS.LOAN.key].reason ?? {
                    label: 'Business',
                    value: 'Business',
                };
                break;
            case SHARED_CONFIG.LOAN_TYPES.PERSONAL:
                salaryAmount = $registerStore[REGISTER_CONFIG.STEPS.LOAN.key].salary
                    ? $registerStore[REGISTER_CONFIG.STEPS.LOAN.key].salary
                    : calculatorType.inputs.salary.limits[country.key].maxAmount / 2;
                borrowAmountMultiplier = $registerStore[REGISTER_CONFIG.STEPS.LOAN.key].borrowAmountMultiplier
                    ? $registerStore[REGISTER_CONFIG.STEPS.LOAN.key].borrowAmountMultiplier
                    : calculatorType.inputs.employment.options[0];
                // borrowAmountMin = calculatorType.inputs.salary.limits[country.key].minAmount / 2;
                borrowAmountMin = 1000;
                borrowAmountMax = 10000;
                borrowAmountStep = calculatorType.inputs.borrowing.limits[country.key].step;
                borrowAmount = $registerStore[REGISTER_CONFIG.STEPS.LOAN.key].amount
                    ? $registerStore[REGISTER_CONFIG.STEPS.LOAN.key].amount
                    : borrowAmountMax;
                numPayments = $registerStore[REGISTER_CONFIG.STEPS.LOAN.key].numPayments ?? numPaymentsOptions[0];
                loanReason = $registerStore[REGISTER_CONFIG.STEPS.LOAN.key].reason ?? {
                    label: 'Personal',
                    value: 'Personal',
                };
                break;
        }

        update();
    });

    function update() {
        // if (loanType == SHARED_CONFIG.LOAN_TYPES.PERSONAL) {
        //     let prevBorrowAmountMax = borrowAmountMax;
        //     borrowAmountMax = salaryAmount * borrowAmountMultiplier.value;

        //     if (borrowAmount > borrowAmountMax || borrowAmount == prevBorrowAmountMax) {
        //         borrowAmount = borrowAmountMax;
        //     }
        // }

        if (loanType == SHARED_CONFIG.LOAN_TYPES.CAR) {
            if (carLoanType.value == SHARED_CONFIG.CAR_LOAN_TYPES.COLLATERAL) {
                borrowAmountMax = carAmount * 0.7; // 70% of car's value

                if (borrowAmount > borrowAmountMax) {
                    borrowAmount = borrowAmountMax;
                }
            } else if (carLoanType.value == SHARED_CONFIG.CAR_LOAN_TYPES.PURCHASE) {
                carDownPaymentAmountMin = carAmount * 0.2; // 20% of the car's value

                if (carAmount - carAmount * 0.9 >= 10000) {
                    carDownPaymentAmountMax = carAmount * 0.9;
                } else {
                    let tempMax = carAmount * 0.9;
                    let delta = 10000 - carAmount - tempMax;
                    carDownPaymentAmountMax = Math.abs(carAmount * 0.9 + delta);
                }

                borrowAmountMin = Math.min(carAmount - carDownPaymentAmountMax, carAmount * 0.5); // 50%  of the car's value
                borrowAmountMax = carAmount * 0.8; // 80% of the car's value

                if (carDownPaymentAmount < carDownPaymentAmountMin) {
                    carDownPaymentAmount = carDownPaymentAmountMin;
                } else if (carDownPaymentAmount > carDownPaymentAmountMax) {
                    carDownPaymentAmount = carDownPaymentAmountMax;
                }

                borrowAmount = carAmount - carDownPaymentAmount;
            } else {
                return;
            }
        }

        let results = calculate(loanType, country, borrowAmount, numPayments.value, {
            carLoanInsuranceType: $registerStore[REGISTER_CONFIG.STEPS.CAR.key]?.insurance?.type?.value,
            isExistingCustomer: $registerStore[REGISTER_CONFIG.STEPS.LOAN.key]?.isExistingCustomer?.value,
        });

        monthlyPayments = [];
        monthlyPayments = results.monthlyPayments;
        borrowAmount = borrowAmount;
        borrowAmountMultiplier = borrowAmountMultiplier;
        totalInterestCharge = results.totalInterestCharge;

        // Car Loans intererst/fees calculations
        if (loanType == SHARED_CONFIG.LOAN_TYPES.CAR) {
            carLoanInterestCharge = totalInterestCharge;
            carLoanLegalFees = 850 + parseFloat(borrowAmount * 0.004);
            totalInterestCharge = carLoanInterestCharge + carLoanLegalFees;
        }

        let monthlyPaymentAmount = monthlyPayments.length ? monthlyPayments[monthlyPayments.length - 1]?.payment : 0;

        let monthlyPaymentAmountBasicInsurance = (
            monthlyPaymentAmount *
            (1 +
                SHARED_CONFIG.PERSONAL_INSURANCE_TYPES.BASIC.percentage[
                    $registerStore[REGISTER_CONFIG.STEPS.ESSENTIALS.key].country?.value
                ])
        ).toFixed(2);

        let monthlyPaymentAmountProInsurance = (
            monthlyPaymentAmount *
            (1 +
                SHARED_CONFIG.PERSONAL_INSURANCE_TYPES.PRO.percentage[
                    $registerStore[REGISTER_CONFIG.STEPS.ESSENTIALS.key].country?.value
                ])
        ).toFixed(2);

        dispatch('calculate', {
            borrowAmount,
            salaryAmount,
            monthlyPaymentAmount,
            monthlyPaymentAmountBasicInsurance,
            monthlyPaymentAmountProInsurance,
            totalInterestCharge,
            numPayments,
            borrowAmountMultiplier,
            loanReason,
            carLoanType,
            carAmount,
            carDownPaymentAmount,
        });
    }

    function loadNumPaymentsOptions() {
        numPaymentsOptions = [];
        let months = [];
        let country = $registerStore[REGISTER_CONFIG.STEPS.ESSENTIALS.key].country?.value;

        if (loanType == SHARED_CONFIG.LOAN_TYPES.PERSONAL) {
            months =
                calculatorType.inputs.numPayments.options[
                    $registerStore[REGISTER_CONFIG.STEPS.ESSENTIALS.key].country?.value ?? 'default'
                ];
        } else if (loanType == SHARED_CONFIG.LOAN_TYPES.CAR) {
            months = calculatorType.inputs.numPayments.options[carLoanType ? carLoanType.value : 'default'];
        } else {
            months =
                calculatorType.inputs.numPayments.options[country] ??
                calculatorType.inputs.numPayments.options['default'];
        }

        // Create the options based on the selectable months
        months.forEach((month) => {
            numPaymentsOptions.push({
                label: `${month} Months`,
                value: month,
            });
        });

        if (numPayments && numPayments.value > months[months.length - 1]) {
            numPayments = numPaymentsOptions[numPaymentsOptions.length - 1];
        }
    }
</script>

<div class="root">
    {#if loanType == SHARED_CONFIG.LOAN_TYPES.BUSINESS && country?.key == SHARED_CONFIG.COUNTRIES.GD.key}
        <div class="form__control">
            <label for="vehicleDebt.hasDebt">Are you an existing MyFi customer?</label>
            <div class="form__select">
                <Select
                    items={REGISTER_CONFIG.OPTIONS.YES_NO}
                    listPlacement="bottom"
                    bind:value={$registerStore[REGISTER_CONFIG.STEPS.LOAN.key].isExistingCustomer}
                    on:select={(event) => {
                        $registerStore[REGISTER_CONFIG.STEPS.LOAN.key].isExistingCustomer = event.detail;
                        update();
                    }}
                    placeholder="Select Yes/No"
                    showChevron={true}
                    showIndicator={true}
                    isClearable={false}
                    isDisabled={disabled}
                />
            </div>
        </div>
    {/if}

    {#if loanType == SHARED_CONFIG.LOAN_TYPES.PERSONAL}
        <div class="form__control">
            <label for="amount">{calculatorType.inputs.employment.label}</label>
            <div class="form__select">
                <Select
                    isClearable={false}
                    isDisabled={disabled}
                    items={calculatorType.inputs.employment.options}
                    bind:value={borrowAmountMultiplier}
                    on:select={(event) => {
                        // loadNumPaymentsOptions();
                        update();
                    }}
                    showIndicator={true}
                    showChevron={true}
                />
            </div>
        </div>
        <div class="form__control">
            <label for="amount">{calculatorType.inputs.salary.label}</label>
            <div class="form__desc">
                Your borrowing eligibility will depend on both your length of employment and monthly salary.
            </div>
            <div class="slider">
                <RangeSlider
                    {disabled}
                    range="min"
                    min={calculatorType.inputs.salary.limits[country.key].minAmount}
                    max={calculatorType.inputs.salary.limits[country.key].maxAmount}
                    step={calculatorType.inputs.salary.limits[country.key].step}
                    values={[salaryAmount]}
                    first="label"
                    last="label"
                    pipstep={500}
                    float
                    formatter={(v) => formatCurrency(v, country.locale, country.currency)}
                    on:change={(event) => {
                        salaryAmount = event.detail.value;
                        update();
                    }}
                    pips
                />
            </div>
        </div>
    {/if}

    {#if loanType == SHARED_CONFIG.LOAN_TYPES.CAR}
        <div class="form__control">
            <label for="amount">{calculatorType.inputs.loanType.label}</label>
            <div class="form__select">
                <Select
                    items={calculatorType.inputs.loanType.options}
                    bind:value={carLoanType}
                    on:select={(event) => {
                        loadNumPaymentsOptions();
                        update();

                        if (carLoanType && carLoanType.value == SHARED_CONFIG.CAR_LOAN_TYPES.PURCHASE) {
                            $registerStore[REGISTER_CONFIG.STEPS.CAR.key].vehicleDebt.hasDebt = {
                                label: 'No',
                                value: false,
                            };
                        } else {
                            $registerStore[REGISTER_CONFIG.STEPS.CAR.key].vehicleDebt.hasDebt = null;
                        }
                    }}
                    isClearable={false}
                    isDisabled={disabled}
                    showIndicator={true}
                    showChevron={true}
                />
            </div>
        </div>
        <div class="form__control">
            <label for="vehicleDebt.hasDebt">Does your vehicle have valid insurance?</label>
            <div class="form__select">
                <Select
                    items={REGISTER_CONFIG.OPTIONS.YES_NO}
                    listPlacement="bottom"
                    bind:value={$registerStore[REGISTER_CONFIG.STEPS.CAR.key].insurance.isValid}
                    on:select={(event) => {
                        $registerStore[REGISTER_CONFIG.STEPS.CAR.key].insurance.isValid = event.detail;
                        $registerStore[REGISTER_CONFIG.STEPS.CAR.key].insurance.type = null;
                        update();
                    }}
                    placeholder="Select Yes/No"
                    showChevron={true}
                    showIndicator={true}
                    isClearable={false}
                    isDisabled={disabled}
                />
            </div>
        </div>

        {#if $registerStore[REGISTER_CONFIG.STEPS.CAR.key].insurance.isValid && $registerStore[REGISTER_CONFIG.STEPS.CAR.key].insurance.isValid.value}
            <div class="form__control">
                <label for="vehicleDebt.hasDebt">What type of insurance does your vehicle have?</label>
                <div class="form__select">
                    <Select
                        items={REGISTER_CONFIG.OPTIONS.INSURANCE_TYPES}
                        listPlacement="bottom"
                        bind:value={$registerStore[REGISTER_CONFIG.STEPS.CAR.key].insurance.type}
                        on:select={(event) => {
                            $registerStore[REGISTER_CONFIG.STEPS.CAR.key].insurance.type = event.detail;
                            update();
                        }}
                        placeholder="Select Insurance Type"
                        showChevron={true}
                        showIndicator={true}
                        isClearable={false}
                        isDisabled={disabled}
                    />
                </div>
            </div>
        {/if}
        {#if carLoanType}
            <div class="form__control">
                <label for="amount">{calculatorType.inputs.car[carLoanType.value].label}</label>
                <div class="form__desc">{calculatorType.inputs.car[carLoanType.value].desc}</div>
                <div class="slider">
                    <RangeSlider
                        {disabled}
                        range="min"
                        min={calculatorType.inputs.car[carLoanType.value].minAmount}
                        max={calculatorType.inputs.car[carLoanType.value].maxAmount}
                        step={calculatorType.inputs.car[carLoanType.value].step}
                        values={[carAmount]}
                        first="label"
                        last="label"
                        pipstep={500}
                        float
                        formatter={(v) => formatCurrency(v, country.locale, country.currency)}
                        on:change={(event) => {
                            carAmount = event.detail.value;
                            update();
                        }}
                        pips
                    />
                </div>
            </div>
        {/if}
        {#if carLoanType && carLoanType.value == SHARED_CONFIG.CAR_LOAN_TYPES.PURCHASE}
            <div class="form__control">
                <label for="amount">{calculatorType.inputs.downPayment.label}</label>
                <div class="form__desc">{calculatorType.inputs.downPayment.desc}</div>
                <div class="slider">
                    <RangeSlider
                        {disabled}
                        range="min"
                        min={carDownPaymentAmountMin}
                        max={carDownPaymentAmountMax}
                        step={calculatorType.inputs.downPayment.step}
                        values={[carDownPaymentAmount]}
                        first="label"
                        last="label"
                        pipstep={500}
                        float
                        formatter={(v) => formatCurrency(v, country.locale, country.currency)}
                        on:change={(event) => {
                            carDownPaymentAmount = event.detail.value;
                            update();
                        }}
                        pips
                    />
                </div>
            </div>
        {/if}
    {/if}

    <div class="form__control">
        <label for="amount">{calculatorType.inputs.borrowing.label}</label>
        <div class="slider">
            <RangeSlider
                disabled={disabled || carLoanType?.value == SHARED_CONFIG.CAR_LOAN_TYPES.PURCHASE}
                range="min"
                min={borrowAmountMin}
                max={borrowAmountMax}
                step={borrowAmountStep}
                values={[borrowAmount]}
                first="label"
                last="label"
                float
                pipstep={500}
                formatter={(v) => formatCurrency(v, country.locale, country.currency)}
                on:change={(event) => {
                    borrowAmount = event.detail.value;
                    update();
                }}
                on:stop={(event) => {
                    if (loanType == SHARED_CONFIG.LOAN_TYPES.BUSINESS) {
                        if (country.key == SHARED_CONFIG.COUNTRIES.BB.key) {
                            let value = event.detail.value;
                            if (value >= 15000 && value <= 25000) {
                                borrowAmount = 25000;
                                update();
                            }
                        } else if (country.key == SHARED_CONFIG.COUNTRIES.TT.key) {
                            let value = event.detail.value;
                            if (value >= 50000 && value <= 75000) {
                                borrowAmount = 75000;
                                update();
                            }
                        }
                    }
                }}
                pips
            />
        </div>
    </div>

    <div class="form__control">
        <label for="amount">{calculatorType.inputs.numPayments.label}</label>
        <div class="form__select">
            <Select
                isDisabled={disabled}
                isClearable={false}
                items={numPaymentsOptions}
                bind:value={numPayments}
                on:select={(event) => {
                    numPayments = event.detail;
                    update();
                }}
                placeholder="Select number of months"
                showIndicator={true}
                showChevron={true}
            />
        </div>
    </div>

    {#if loanType == SHARED_CONFIG.LOAN_TYPES.PERSONAL}
        <div class="form__control">
            <label for="amount">What will you be using these funds for?</label>
            <div class="form__select">
                <Select
                    isDisabled={disabled}
                    isClearable={false}
                    items={loanReasonOptions}
                    bind:value={loanReason}
                    on:select={(event) => {
                        loanReason = event.detail;
                        update();
                    }}
                    showChevron={true}
                    showIndicator={true}
                />
            </div>
        </div>
    {/if}

    <div class="calculator-values">
        <div class="calculator-values__item">
            <label class="calculator-values__label" for="">{calculatorType.labels.borrowing}</label>
            <div class="calculator-values__value">
                {formatCurrency(borrowAmount, country.locale, country.currency)}
                {#if activeLoan}
                    +
                    <span class="calculator-values__critical"
                        >{formatCurrency(activeLoan.amountDue, country.locale, country.currency)}
                        (Outstanding Balance)</span
                    >
                    =
                    {formatCurrency(
                        parseFloat(activeLoan.amountDue) + parseFloat(borrowAmount),
                        country.locale,
                        country.currency,
                    )}
                {/if}
            </div>
        </div>

        {#if loanType == SHARED_CONFIG.LOAN_TYPES.CAR}
            <div class="calculator-values__item">
                <label class="calculator-values__label" for="">Total Interest</label>
                <div class="calculator-values__value">
                    {formatCurrency(carLoanInterestCharge, country.locale, country.currency)}
                </div>
            </div>
            <div class="calculator-values__item">
                <label class="calculator-values__label" for="">Legal Fees</label>
                <div class="calculator-values__value">
                    {formatCurrency(carLoanLegalFees, country.locale, country.currency)}
                </div>
            </div>
        {:else}
            <div class="calculator-values__item">
                <label class="calculator-values__label" for="">{calculatorType.labels.fees}</label>
                <div class="calculator-values__value">
                    {formatCurrency(totalInterestCharge, country.locale, country.currency)}
                </div>
            </div>
        {/if}
        <div class="calculator-values__item">
            <label class="calculator-values__label" for="">{calculatorType.labels.paymentPlan}</label>
            <div class="calculator-values__value">
                <div class="payment-plan">
                    <div class="payment-plan__grid">
                        {#each monthlyPayments as payment, i (payment.id)}
                            <div
                                class="payment animate__animated animate__fadeInDown"
                                style={'animation-delay: ' + i * 0.05 + 's'}
                            >
                                <div class="payment__date">{payment.date}</div>
                                <div class="payment__amount">
                                    {#if payment.payment}
                                        {formatCurrency(payment.payment, country.locale, country.currency)}
                                    {:else}No Payment{/if}
                                </div>
                            </div>
                        {/each}
                    </div>
                </div>
            </div>
        </div>
        <div class="form__disclaimer">{calculatorType.labels.disclaimer}</div>
    </div>
</div>

<style lang="scss" type="text/scss">
    @use 'src/sass/base' as *;

    .root {
        width: 100%;
    }

    .form {
        &__control {
            margin: em(40) 0;
        }

        &__disclaimer {
            margin: em(40) 0;
            font-size: em(14);
            line-height: 1.25;
            color: $color-gray-text;

            &:before {
                content: '* ';
                color: $color-primary;
            }
        }

        &__select {
            width: 100%;
            font-size: em(14);
            font-weight: $fw-regular;
            --inputPadding: 15px 20px;
            --border: 1px solid #c7c7ca;
            --borderFocusColor: #662cc8;
            --borderRadius: 8px;
            --itemHoverBG: #f4f4f4;
            --itemIsActiveBG: #662cc8;
            --multiItemActiveBG: #662cc8;
            --placeholderColor: #c7c7ca;
            --multiClearBG: #999999;
            --disabledBackground: #fbfbfb;
            --disabledBorderColor: #c7c7ca;
            --disabledColor: #000;
        }

        &__desc {
            margin: em(15) 0;
            font-size: em(14);
            color: $color-gray-text;
        }
    }

    .slider {
        margin: em(60) 0 em(60) 0;
        --range-handle: #d83e33;
        --range-handle-inactive: #d83e33;
        --range-handle-focus: #d83e33;
        --range-range: #d83e33;
        --range-range-inactive: #d83e33;
    }

    .calculator-values {
        margin: em(50) 0 em(60) 0;

        &__critical {
            color: $color-red;
        }

        &__item {
            margin: em(40) 0;
        }

        &__label {
            color: $color-primary;
            font-size: em(18);
            font-weight: $fw-medium;
        }

        &__value {
            font-size: em(18);
            font-weight: $fw-medium;
        }
    }

    .payment-plan {
        &__grid {
            display: grid;
            justify-content: center;
            align-items: stretch;
            width: 100%;
            margin-top: em(10);
            grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
            gap: 0;

            @include for-tablet-portrait-up {
                grid-template-columns: repeat(auto-fit, minmax(25%, 1fr));
                gap: em(10);
            }

            @include for-tablet-landscape-up {
                grid-template-columns: repeat(auto-fit, minmax(20%, 1fr));
                gap: em(20);
            }
        }
    }

    .payment {
        margin: em(10) 0;
        font-size: em(12);
        font-weight: $fw-light;
        color: $color-gray-text;

        &__date {
            margin-bottom: em(5);
            font-weight: $fw-regular;
        }
    }
</style>
