<script>
    import { onMount } from 'svelte';
    import { Chart as ChartJS, Title, Tooltip, Legend, ArcElement, CategoryScale } from 'chart.js';
    import Router, { location } from 'svelte-spa-router';
    import Modal from 'svelte-simple-modal';
    import { initializeApp, getApp } from 'firebase/app';
    import { initializeAppCheck, ReCaptchaV3Provider } from 'firebase/app-check';
    import { getAuth, connectAuthEmulator } from 'firebase/auth';
    import { getFunctions, connectFunctionsEmulator } from 'firebase/functions';
    import { getFirestore, connectFirestoreEmulator } from 'firebase/firestore';
    import { push, location as routerLocation, replace } from 'svelte-spa-router';

    export let routes;

    // Set up Firebase config for different environments.
    let firebaseConfig;
    let appCheckSiteKey;

    if ('<@ENV@>' != 'PRODUCTION') {
        firebaseConfig = {
            apiKey: 'AIzaSyAivljzc4Tj_JPxqWVGxReAkDOH4aRkJbs',
            authDomain: 'tf-portal-stage.firebaseapp.com',
            projectId: 'tf-portal-stage',
            storageBucket: 'tf-portal-stage.appspot.com',
            messagingSenderId: '289550291637',
            appId: '1:289550291637:web:8bf47b5aa49b55e947a4fc',
            measurementId: 'G-2CK75LE5ZL',
        };
    } else {
        firebaseConfig = {
            apiKey: 'AIzaSyAB15cuvU3ZH-X8Ze1p-1uiL0ae-dGe6F0',
            authDomain: 'tf-portal-prod.firebaseapp.com',
            projectId: 'tf-portal-prod',
            storageBucket: 'tf-portal-prod.appspot.com',
            messagingSenderId: '951756197955',
            appId: '1:951756197955:web:b428074477328e5fc1c4f6',
            measurementId: 'G-1D164KG97M',
        };
    }

    initializeApp(firebaseConfig);

    $: {
        // Scroll to the top of the page whenever the '$location' changes
        if ($location) window.scrollTo(0, 0);
    }

    onMount(() => {
        // Set up Firebase environments
        if ('<@ENV@>' == 'PRODUCTION') {
            // Todo: Add production credentials
            console.debug('Environment: Production');
            appCheckSiteKey = '6LcrZl4gAAAAADvFBPP0L5Kkk7q1lcZ4GNkujpSe';
        } else if ('<@ENV@>' == 'STAGING') {
            console.debug('Environment: Staging');
            appCheckSiteKey = '6Lfl1_QeAAAAANAwiKMWWp8np6vmWFh3hTVmToHe';
        } else {
            console.debug('Environment: Development');

            // Connect to Firebase Emulator
            // TODO: Fix the debug token
            // console.debug('APPCHECK_DEBUG_MODE enabled');
            // self.FIREBASE_APPCHECK_DEBUG_TOKEN = true;
            appCheckSiteKey = '6Lfl1_QeAAAAANAwiKMWWp8np6vmWFh3hTVmToHe';

            const functions = getFunctions(getApp());
            connectFunctionsEmulator(functions, 'localhost', 5003);
            connectAuthEmulator(getAuth(), 'http://localhost:9099');
            connectFirestoreEmulator(getFirestore(), 'localhost', 8080);
        }

        // Set up App Check
        if (appCheckSiteKey) {
            initializeAppCheck(app, {
                provider: new ReCaptchaV3Provider(appCheckSiteKey),
                isTokenAutoRefreshEnabled: true,
            });

            console.debug('AppCheck initialized');
        }

        // Register Chart JS
        ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale);

        triggerRedirects();
    });

    function triggerRedirects() {
        const route = window.location.pathname;
        const queryParams = parseQueryParams();

        if (route == '/register' && (queryParams.type || queryParams.country)) {
            const searchParams = new URLSearchParams(queryParams).toString();
            const baseUrl = window.location.protocol + '//' + window.location.host;
            window.history.replaceState({}, '', baseUrl);
            push(`/register${searchParams ? '?' + searchParams : ''}`);
            return;
        }
    }

    function parseQueryParams() {
        // Use search instead of hash to get the query string
        const searchString = window.location.search;

        if (!searchString) {
            return {};
        }

        // URLSearchParams will automatically handle the leading '?'
        const searchParams = new URLSearchParams(searchString);
        const params = {};

        for (const [key, value] of searchParams.entries()) {
            params[key] = value;
        }

        return params;
    }

    function handleConditionsFailed(event) {
        event.detail.userData.onConditionsFailed();
    }
</script>

<main>
    <Modal>
        <Router {routes} on:conditionsFailed={handleConditionsFailed} />
    </Modal>
</main>

<style lang="scss">
    @use 'src/sass/base' as *;
    @import url('https://fonts.googleapis.com/css2?family=Inter:ital,wght@0,100;0,400;0,500;0,700;0,900;1,300&display=swap');
    @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,300:0,400;0,500;0,700;0,900;1,300&display=swap');

    :global(body) {
        overscroll-behavior: none;
        background: $color-white;
        font-size: em(14);
        font-family: $ff-headers;
        font-weight: $fw-regular;
        line-height: 1;

        @include for-tablet-portrait-up {
            font-size: em(14);
        }
    }

    :global(a) {
        text-decoration: none;
        color: $color-primary;

        &:hover {
            color: darken($color-primary, 10%);
        }
    }

    :global(p) {
        line-height: 1.25;
    }

    :global(strong) {
        font-weight: $fw-bold;
    }

    :global(form) {
        width: 100%;
        margin: 0;
        padding: 0;
    }

    :global(input) {
        width: 100%;
        outline: 0;
        background: $color-white;
        border: 1px solid $color-gray-dark;
        border-radius: 8px;
        padding: 10px 10px;
        font-size: em(16);
        color: $color-black;
        font-weight: $fw-regular;
        letter-spacing: 0.3px;
        transition: 0.25s all ease;

        &:focus {
            border-color: $color-primary;
        }

        &:hover {
            border-color: $color-primary;
        }

        &:disabled {
            background: $color-gray-light;
        }

        &::placeholder {
            font-weight: $fw-regular;
            color: $color-gray-dark;
        }
    }

    :global(label) {
        display: block;
        margin: em(10) 0;
        font-weight: $fw-regular;
        font-size: em(16);
        color: $color-black;
    }

    :global(textarea) {
        width: 100%;
        resize: none;
        height: 200px;
        padding: em(25);
        color: $color-gray-text;
        font-size: em(14);
        line-height: 1.25;
        outline: none;

        &:focus {
            border-color: $color-primary;
        }
    }

    :global(.pip) {
        font-size: em(10);
        color: $color-gray;
    }

    :global(.rangeHandle) {
        z-index: 0 !important;
    }

    :global(.rangeFloat) {
        opacity: 1 !important;
    }

    :global(.bg) {
        backdrop-filter: blur(5px);
        animation-duration: 0.25s;
    }
</style>
