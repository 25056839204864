<script>
    import { getContext } from 'svelte';
    import Button from 'components/Button';

    export let onClose = () => {};

    const { close } = getContext('simple-modal');

    function handleClose() {
        onClose();
        close();
    }
</script>

<div class="modal">
    <div class="modal-content">
        <h2 class="modal-content__title">Contact us</h2>
        <div class="modal__desc">
            <p>Axcel Finance is a web-based lender operating across the Caribbean.</p>
            <div class="contacts">
                <div class="contacts__contact">
                    <div class="contacts__name">Contact us in Antigua</div>
                    <div class="contacts__number"><i class="fa fa-normal fa-phone"></i> 268-562-8222</div>
                    <a href="mailto:antigua@axcelfinance.com" class="contacts__number"
                        ><i class="fa fa-normal fa-envelope"></i> antigua@axcelfinance.com</a
                    >
                </div>
                <!-- <div class="contacts__contact">
                    <div class="contacts__name">Contact us in Barbados</div>
                    <div class="contacts__number"><i class="fa fa-normal fa-phone"></i> 246-228-8222</div>
                    <a href="mailto:barbados@axcelfinance.com" class="contacts__number"
                        ><i class="fa fa-normal fa-envelope"></i> barbados@axcelfinance.com</a
                    >
                </div> -->
                <div class="contacts__contact">
                    <div class="contacts__name">Contact us in Grenada</div>
                    <div class="contacts__number"><i class="fa fa-normal fa-phone"></i> 473-435-8222</div>
                    <a href="mailto:grenada@axcelfinance.com" class="contacts__number"
                        ><i class="fa fa-normal fa-envelope"></i> grenada@axcelfinance.com</a
                    >
                </div>
                <!-- <div class="contacts__contact">
                    <div class="contacts__name">Contact us in St. Lucia</div>
                    <div class="contacts__number"><i class="fa fa-normal fa-phone"></i> 758-450-8222</div>
                    <a href="mailto:slu@axcelfinance.com" class="contacts__number"
                        ><i class="fa fa-normal fa-envelope"></i> slu@axcelfinance.com</a
                    >
                </div> -->
            </div>
        </div>
    </div>
    <div class="actions">
        <Button primary small onClick={handleClose}>OK</Button>
    </div>
</div>

<style lang="scss">
    @use 'src/sass/base' as *;

    .modal-content {
        margin-bottom: em(30);
        padding: em(30) em(30) 0 em(30);

        &__title {
            margin-bottom: em(25);
            font-size: em(20);
            font-weight: $fw-bold;
            color: $color-primary;
            line-height: 1.25;
        }

        :global(p) {
            margin-bottom: em(25);
        }
    }

    .contacts {
        display: block;
        justify-content: flex-start;
        grid-template-columns: 1fr 1fr;
        flex-flow: row wrap;
        align-items: center;
        margin-top: em(30);

        @include for-tablet-landscape-up {
            display: grid;
        }

        &__contact {
            width: 100%;
            margin: em(20) 0;
            font-size: em(16);
            display: flex;
            flex-flow: column nowrap;
            align-items: start;
            justify-content: start;
            row-gap: 5px;
        }

        &__name {
            margin-bottom: em(10);
            color: $color-black;
        }

        &__number {
            display: flex;
            justify-content: start;
            align-items: center;
            column-gap: 5px;
            flex-wrap: nowrap;
            margin-bottom: em(5);
            font-weight: $fw-bold;
            color: $color-primary;
        }
    }

    .actions {
        padding: em(10) em(25);
        border-radius: 0 0 em(25) em(25);
        background: $color-gray-light;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        width: auto;
    }
</style>
